import { Box } from "@mui/material";

export const Main = () => {
  return (
    <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center", height: "100%" }}>
      <a href="https://cascadify.io" target={"_blank"} rel="noreferrer">
        <img src={"/files/logo.png"} width={500} height={500} alt="cascadify.io" />
      </a>
    </Box>
  );
};
