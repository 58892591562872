import { FC } from "react";
import { AppBar, Box, useMediaQuery, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { companyName } from "@framework/constants";
import { ConnectButton } from "@dzambalaorg/provider-wagmi";

import { Sections } from "./sections";
import { StyledGrow, StyledLink, StyledToolbar } from "./styled";

export const Header: FC = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AppBar position="fixed">
      <StyledToolbar>
        <StyledLink component={RouterLink} to="/">
          {!isSm && <Box>{companyName} - Admin panel</Box>}
        </StyledLink>
        <StyledGrow />
        <ConnectButton />
        <Sections />
      </StyledToolbar>
    </AppBar>
  );
};
